import DatosSPG from "./funciones"

class Fecha
{
    /**
     * Retorna una fecha formato String, del dia de hoy, formato dd/mm/yyyy */
    Hoy()
    {
        let dFecha = new Date()
        let cCeroM = ""
        let cCeroD = ""

        if (dFecha.getMonth() < 9)
            cCeroM = "0"

        if (dFecha.getDate() <= 9)
            cCeroD = "0"

        return dFecha.getFullYear() + "/" + cCeroM + (dFecha.getMonth() + 1) + "/" + cCeroD + dFecha.getDate()
    }

    /**
     * Retorna una fecha formato String, del dia de hoy, formato dd/mm/yyyy */
    HoyMenosXDias(nDias, bIng = false, bSQL = false)
    {
        let dFecha = new Date()
        let cCeroM = ""
        let cCeroD = ""

        dFecha.setDate(dFecha.getDate() - nDias)
 
        if (dFecha.getMonth() < 9)
            cCeroM = "0"

        if (dFecha.getDate() <= 9)
            cCeroD = "0"
 
        if (bSQL)
        {
            return dFecha.getFullYear() + cCeroM + (dFecha.getMonth() + 1) + cCeroD + (dFecha.getDate())
        }
        else  
        {
            if (bIng)
            {
                return dFecha.getFullYear() + "-" + cCeroM + (dFecha.getMonth() + 1) + "-" + cCeroD + (dFecha.getDate())
            }
            else 
            {
                return cCeroD + (dFecha.getDate()) + "/" + cCeroM + (dFecha.getMonth() + 1) + "/" + dFecha.getFullYear()
            }
        }
    }

    /**
     * Retorna una fecha formato String, del dia de hoy, formato dd/mm/yyyy */
    HoyMasXDias(nDias, bIng = false, bSQL = false)
    {
        let dFecha = new Date()
        let cCeroM = ""
        let cCeroD = ""

        dFecha.setDate(dFecha.getDate() + nDias)
 
        if (dFecha.getMonth() < 9)
            cCeroM = "0"

        if (dFecha.getDate() <= 9)
            cCeroD = "0"
 
        if (bSQL)
        {
            return dFecha.getFullYear() + cCeroM + (dFecha.getMonth() + 1) + cCeroD + (dFecha.getDate())
        }
        else  
        {
            if (bIng)
            {
                return dFecha.getFullYear() + "-" + cCeroM + (dFecha.getMonth() + 1) + "-" + cCeroD + (dFecha.getDate())
            }
            else 
            {
                return dFecha.getFullYear() + "/" + cCeroM + (dFecha.getMonth() + 1) + "/" + cCeroD + (dFecha.getDate())
            }
        }
    }

    /**
     * Retorna una fecha formato String, del dia de hoy, formato dd/mm/yyyy */
    FechaMasXDias(dFecha, nDias, bIng = false, bSQL = false)
    {
        dFecha = this.SQL(dFecha)

        let cCeroM = ""
        let cCeroD = ""

        dFecha.setDate(dFecha.getDate() + nDias)
 
        if (dFecha.getMonth() < 9)
            cCeroM = "0"

        if (dFecha.getDate() <= 9)
            cCeroD = "0"
 
        if (bSQL)
        {
            return dFecha.getFullYear() + cCeroM + (dFecha.getMonth() + 1) + cCeroD + (dFecha.getDate())
        }
        else  
        {
            if (bIng)
            {
                return dFecha.getFullYear() + "-" + cCeroM + (dFecha.getMonth() + 1) + "-" + cCeroD + (dFecha.getDate())
            }
            else 
            {
                return dFecha.getFullYear() + "/" + cCeroM + (dFecha.getMonth() + 1) + "/" + cCeroD + (dFecha.getDate())
            }
        }
    }

    /**
     * Retorna una fecha formato String, del dia de hoy, formato dd/mm/yyyy */
    FechaMenosXDias(dFecha, nDias, bIng = false, bSQL = false)
    {
        dFecha = this.SQL(dFecha)
        
        let cCeroM = ""
        let cCeroD = ""

        dFecha.setDate(dFecha.getDate() - nDias)
 
        if (dFecha.getMonth() < 9)
            cCeroM = "0"

        if (dFecha.getDate() <= 9)
            cCeroD = "0"
    
        if (bSQL)
        {
            return dFecha.getFullYear() + cCeroM + (dFecha.getMonth() + 1) + cCeroD + (dFecha.getDate())
        }
        else  
        {
            if (bIng)
            {
                return dFecha.getFullYear() + "-" + cCeroM + (dFecha.getMonth() + 1) + "-" + cCeroD + (dFecha.getDate())
            }
            else 
            {
                return dFecha.getFullYear() + "/" + cCeroM + (dFecha.getMonth() + 1) + "/" + cCeroD + (dFecha.getDate())
            }
        }
    }
    
    /**
     * Retorna una fecha formato String, del dia de hoy, formato dd/mm/yyyy */
    HoyIng(bInput, bHora = false)
    {
        let dFecha = new Date()
        const spg = new DatosSPG()
        let cHora = ""
        
        if (bHora)
        {
            cHora = "T" + spg.PadLeft(dFecha.getHours(), 2) + ":" + spg.PadLeft(dFecha.getMinutes(), 2) + ":" + spg.PadLeft(dFecha.getSeconds(), 2)
        }

        if (bInput)
            return dFecha.getFullYear() + "-" + spg.PadLeft((dFecha.getMonth() + 1), 2) + "-" + spg.PadLeft(dFecha.getDate(), 2) + cHora
        else 
            return dFecha.getFullYear() + "/" + spg.PadLeft((dFecha.getMonth() + 1), 2) + "/" + spg.PadLeft(dFecha.getDate(), 2) + cHora
    }
    
    /**
     * Retorna una fecha formato String, del dia 1 del mes corriente, formato dd/mm/yyyy */
    Mes()
    {
        let dFecha = new Date()
        let cCeroM = ""

        if (dFecha.getMonth() < 9)
            cCeroM = "0"

        return  "01/" + cCeroM + (dFecha.getMonth() + 1) + dFecha.getFullYear()
    }
    
    /**
     * Retorna una fecha formato String, del dia 1 del mes corriente, formato dd/mm/yyyy */
    MesIng(bInput = false)
    {
        let dFecha = new Date()
        let cCeroM = ""

        if (dFecha.getMonth() < 9)
            cCeroM = "0"

        if (bInput)
            return dFecha.getFullYear() + "-" + cCeroM + (dFecha.getMonth() + 1) + "-01"
        else 
            return dFecha.getFullYear() + "/" + cCeroM + (dFecha.getMonth() + 1) + "/01"
    }
     
    /**
     * Retorna una fecha formato String, del dia 1 del año corriente, formato dd/mm/yyyy */
    AñoIng(bInput = false)
    {
        let dFecha = new Date() 

        if (bInput)
        {
            return dFecha.getFullYear() + "-01-01"
        }
        else
        {
            return dFecha.getFullYear() + "/01/01"
        }
    }
     
    /**
     * Retorna una fecha formato String, del dia 1 del año corriente, formato dd/mm/yyyy */
    Año(bInput = false)
    {
        let dFecha = new Date() 

        if (bInput)
        {
            return  dFecha.getFullYear() + "-01-01"
        }
        else
        {
            return  "01/01/" + dFecha.getFullYear()
        }
    }

    /**
     * Recibe una fecha, formato SQL SERVER, y un bool, para saber si retorna con la hora
     * Retorna formato fecha */
    SQL(dFecha, bHora = false)
    {
        let cFecha = ""
        
        if (typeof dFecha.date !== "undefined")
            cFecha = dFecha.date.toString().substr(0, 10)
        else   
            cFecha = dFecha.substr(0, 10)
        
        let cHora = ""

        if (bHora)
        {
            cHora = " " + dFecha.date.toString().substr(10, 8)
        }
        
        return new Date(cFecha.substr(5, 2) + "-" + cFecha.substr(8, 2) + "-" + cFecha.substr(0, 4) + cHora)
    }

    /**
     * Recibe una fecha, formato SQL SERVER o Date, el segundo parametro es para indicar si la fecha enviada es un string en formato inglés
     * Retorna formato string, dd/mm/yyyy */
    FormatoArg(dFecha = this.HoyIng(), bIng = true, bIncluirHora = false)
    {
        let dFechaRet = ""
        
        if (dFecha)
        {
            switch(typeof dFecha)
            {
                case "string": 
                    if (bIng)
                    {
                        dFechaRet = dFecha.substr(8, 2) + "-" + dFecha.substr(5, 2) + "-" + dFecha.substr(0, 4) + 
                            (bIncluirHora ? " " + dFecha.substr(11, 2) + ":" + dFecha.substr(14, 2) + ":" + dFecha.substr(17, 2) : "")
                    }
                    else
                    {
                        dFechaRet = dFecha.substr(8, 2) + "/" + dFecha.substr(5, 2) + "/" + dFecha.substr(0, 4) + 
                            (bIncluirHora ? " " + dFecha.substr(11, 2) + ":" + dFecha.substr(14, 2) + ":" + dFecha.substr(17, 2) : "")
                    }
                    
                    break 
                case "object":
                    const dFechaSQL = this.SQL(dFecha)

                    let cCeroM = ""
                    let cCeroD = ""

                    if (dFechaSQL.getMonth() < 9)
                        cCeroM = "0"

                    if (dFechaSQL.getDate() <= 9)
                        cCeroD = "0"
                    
                    dFechaRet = cCeroD + dFechaSQL.getDate() + "/" + cCeroM + (dFechaSQL.getMonth() + 1) + "/" + dFechaSQL.getFullYear() + 
                        (bIncluirHora ? " " + (dFecha.getHours() <= 9 ? "0" + dFecha.getHours() : dFecha.getHours()) + ":" + (dFecha.getMinutes() <= 9 ? dFecha.getMinutes() : dFecha.getMinutes()) + ":" + (dFecha.getSeconds() <= 9 ? "0" + dFecha.getSeconds() : dFecha.getSeconds()) : "")

                    break
                default:
                    dFechaRet = dFecha.getDate() + "/" + (dFecha.getMonth() + 1) + "/" + dFecha.getFullYear() + 
                        (bIncluirHora ? " " + (dFecha.getHours() <= 9 ? "0" + dFecha.getHours() : dFecha.getHours()) + ":" + (dFecha.getMinutes() <= 9 ? dFecha.getMinutes() : dFecha.getMinutes()) + ":" + (dFecha.getSeconds() <= 9 ? "0" + dFecha.getSeconds() : dFecha.getSeconds()) : "")
            }
        }
        else 
        {
            dFechaRet = ""
        }

        return dFechaRet
    }

    /**
     * 
     * dFecha Recibe una fecha, formato Date
     * Retorna formato string, yyyy-mm-dd 
     * Nornalmente será utilizado para rellenar controles tipo fecha 
     * 
     */ 
    FormatoIng(dFecha, bIncluirHora = false, bAceptarNULL = false)
    {
        if (!dFecha && !bAceptarNULL)
        {
            dFecha = new Date()
        }
        
        let cCeroM = ""
        let cCeroD = ""

        if (bAceptarNULL && !dFecha)
        {
            return "0000-0-0"
        }
        else 
        {
            if (typeof dFecha !== "string")
            {
                if (dFecha.getMonth() < 9)
                    cCeroM = "0"

                if (dFecha.getDate() <= 9)
                    cCeroD = "0"

                return dFecha.getFullYear() + "-" + cCeroM + (dFecha.getMonth() + 1) + "-" + cCeroD + dFecha.getDate() + 
                    (bIncluirHora ? " " + (dFecha.getHours() <= 9 ? "0" + dFecha.getHours() : dFecha.getHours()) + ":" + (dFecha.getMinutes() <= 9 ? dFecha.getMinutes() : dFecha.getMinutes()) + ":" + (dFecha.getSeconds() <= 9 ? "0" + dFecha.getSeconds() : dFecha.getSeconds()) : "")
            }
            else
            {
                return dFecha.substr(0, 4) + "-" + dFecha.substr(5, 2) + "-" + dFecha.substr(8, 2) + (bIncluirHora ? " " + dFecha.substr(11, 8) : "")
            }
        }
    }

    /**
     * 
     * dFecha Recibe una fecha, formato Date
     * Retorna formato string, yyyy-mm-dd 
     * Nornalmente será utilizado para enviar por parametro a la API 
     * 
     */ 
    FormatoParamSQL(dFecha, bIncluirHora = false)
    {
        if (!dFecha)
        {
            dFecha = new Date()
        }
        
        let cCeroM = ""
        let cCeroD = ""
        
        if (typeof dFecha !== "string")
        {
            if (dFecha.getMonth() < 9)
                cCeroM = "0"

            if (dFecha.getDate() <= 9)
                cCeroD = "0"
            
            return dFecha.getFullYear() + cCeroM + (dFecha.getMonth() + 1) + cCeroD + dFecha.getDate() + 
                (bIncluirHora ? " " + (dFecha.getHours() <= 9 ? "0" + dFecha.getHours() : dFecha.getHours()) + ":" + (dFecha.getMinutes() <= 9 ? dFecha.getMinutes() : dFecha.getMinutes()) + ":" + (dFecha.getSeconds() <= 9 ? "0" + dFecha.getSeconds() : dFecha.getSeconds()) : "")
        }
        else
        {
            return dFecha.substr(0, 4) + dFecha.substr(5, 2) + dFecha.substr(8, 2) + (bIncluirHora ? " T" + dFecha.substr(11, 8) : "")
        }
    }
    
    /**
     * 
     * dFechaDesde Recibe una fecha desde, formato Date o String con "-" separando meses y dias
     * dFechaHasta Recibe una fecha hasta, formato Date o String con "-" separando meses y dias
     * Retorna formato int, la diferencia de días  
     * 
     */
    Diferencia(dFechaDesde, dFechaHasta)
    {
        if (typeof dFechaDesde === "string")
        {
            dFechaDesde = new Date(dFechaDesde)
        }
        
        if (typeof dFechaHasta === "string")
        {
            dFechaHasta = new Date(dFechaHasta)
        }

        console.log(dFechaDesde)
        console.log(dFechaHasta)
        
        let nDiferencia = dFechaDesde.getTime() - dFechaHasta.getTime();
      
        return nDiferencia / (1000 * 3600 * 24)
    }
}

export default Fecha;